import React, {useEffect, useState} from 'react';
import AdminHeader from '../components/AdminHeader';
import request from '../utils/request';
import LoadingHomeComponent from "../components/packageComponents/LoadingHomeComponent";
import SelectDropdown from "../components/SelectDropdown";

export default function AssignAdditionalDelivery() {
    const [hosts, setHosts] = useState();
    const [activeHost, setActiveHost] = useState();
    const [deliveryFee, setDeliveryFee] = useState('');
    const [loading, setLoading] = useState();
    const [properties, setProperties] = useState([]);
    const [activeProperties, setActiveProperties] = useState([]);

    useEffect(() => {
        const getHosts = async () => {
            const response = await request.get('/hosts');
            setHosts(response.data);
        };
        getHosts();
    }, []);

    useEffect(() => {
        if (activeHost && activeHost.value) {
            getProperties();
        }
    }, [activeHost]);

    const getProperties = async () => {
        setLoading(true);
        const response = await request.get(`/properties/host/${activeHost.value}`);
        setProperties(response.data);
        setLoading(false);
    };

    const propertiesData = properties && properties.map((property, index) => {
        return {
            label: property.address?.line1 + ' ' + property.address?.city,
            value: property._id
        }
    })
    const hostsData = hosts && hosts.map((host, index) => {
        return {
            label: host.firstName + ' ' + host.lastName,
            value: host._id
        }
    })

    const assignDeliveryFee = async () => {
        const propertyIds = activeProperties.map(property => property.value);
        const data = {
            propertyIds,
            deliveryFee
        }

        setLoading(true);
        await request.post('/properties/assign-delivery-store', data);
        setActiveHost({})
        setDeliveryFee('');
        setActiveProperties([])
        setLoading(false)
    }

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader heading={"Assign Additional Delivery"} pages={"Pages / Assign Additional Delivery"}/>
                </div>
                <div className="px-6 py-5 h-screen overflow-auto">
                    <div
                        className="relative bg-white overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg p-8">
                        {loading ? <LoadingHomeComponent/> : (
                            <>
                                <div className='py-4'>
                                    <div className='flex pb-2 font-bold'>Select Host</div>
                                    <SelectDropdown
                                        value={activeHost}
                                        options={hostsData}
                                        isMulti={false}
                                        onChange={option => setActiveHost(option)}
                                    />
                                </div>
                                <div className='py-4'>
                                    <div className="flex pb-2 font-bold justify-between items-center">
                                        <p>
                                            Select Properties
                                        </p>
                                        <div className="flex items-center space-x-2">
                                            <label>Select All</label>
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    setActiveProperties(e.target.checked ? propertiesData : [])
                                                }
                                            />
                                        </div>
                                    </div>
                                    <SelectDropdown
                                        value={activeProperties}
                                        options={propertiesData}
                                        isMulti={true}
                                        onChange={option => setActiveProperties(option)}
                                    />
                                </div>
                                <div className='py-4'>
                                    <div className='flex pb-2 font-bold'>Enter Delivery Fee</div>
                                    <input
                                        type='number'
                                        value={deliveryFee}
                                        onChange={(e) => setDeliveryFee(e.target.value)}
                                        className='w-full border-black text-boxheight2 rounded'
                                        style={{height: '40px', border: '1px solid black'}}/>
                                </div>
                                <div className='py-4 justify-end flex'>
                                    <button className='bg-primary text-white px-4 py-2 rounded-md'
                                            onClick={assignDeliveryFee}>Assign Delivery Fee
                                    </button>
                                </div>
                            </>
                        )}
                        {
                            activeProperties && activeProperties.length > 0 && (
                                <table className="table-auto w-full">
                                    <thead>
                                    <tr>
                                        <th className="border p-4">Property ID</th>
                                        <th className="border p-4">Delivery Fee</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {activeProperties.map((propertyData) => {
                                        const comparedProperty = properties && properties.filter((property) => property._id === propertyData.value)[0];
                                        return (
                                            <tr className=''>
                                                <td className="border p-4">{comparedProperty?.address?.line1} {comparedProperty?.address?.city}</td>
                                                <td className="border p-4">{comparedProperty?.deliveryFee}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

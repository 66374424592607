import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import SideNav from "./components/SideNav";
import {
  LoginScreen,
  SignupScreen,
  DashboardScreen,
  DriverListScreen,
  OrderListScreen,
  HostListScreen,
  HostDetails,
  DriverDetailsScreen,
  PropertyDetailsScreen
} from "./screens";
import HostPersonalDetails from "./screens/HostDetailScreen";
import OrderDetails from "./screens/OrderDetails";
import AdminPackages from "./screens/AdminPackages";
import PrivateRoutes from "./components/utilityComponents/PrivateRoutes";
import PageNotFoundScreen from "./screens/PageNotFoundScreen";
import SupportScreen from "./screens/SupportScreen";
import ParserDetailScreen from "./screens/ParserDetailScreen";
import SupportChat from "./screens/SupportChat";
import Offers from "./screens/Offers";
import OfferCreate from "./screens/OfferCreate";
import OfferEdit from "./screens/OfferEdit";
import PropertyOffers from "./screens/PropertyOffers";
import PropertyOffersCreate from "./screens/PropertyOffersCreate";
import PropertyOfferEdit from "./screens/PropertyOfferEdit";
import DriverPromotions from "./screens/DriverPromotions";
import DriverPromotionCreate from "./screens/DriverPromotionCreate";
import DriverPromotionEdit from "./screens/DriverPromotionEdit";
import TrackOrder from "./screens/TrackOrder";
import ManageOrderStatus from "./screens/ManageOrderStatus";
import OrderFeedback from "./screens/OrderFeedback";
import GroceryListing from "./screens/GroceryListing";
import AssignPropertyStore from "./screens/AssignPropertyStore";
import AssignAdditionalDelivery from "./screens/AssignAdditionalDelivery";



const App = () => {
  const { loading, adminInfo, error } = useSelector((state) => {
    return state.login;
  });

  return (
    <>
      {/* We have to use !hostInfo condition as per logic */}
      <div className="flex w-full min-h-screen overflow-auto relative">
        {adminInfo && <SideNav />}
        <Routes>
          <Route path='/' element={<LoginScreen />} />
          <Route path='/login' element={<LoginScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/hostList" element={<HostListScreen />} />
            <Route path="/hostList/:hid" element={<HostDetails />} />
            <Route path="/hostList/:hid/parser/:prid" element={<ParserDetailScreen />} />
            <Route path="/hostList/:hid/:pid" element={<PropertyDetailsScreen />} />
            <Route path="/adminDetails" element={<HostPersonalDetails />} />
            <Route path="/driverList" element={<DriverListScreen />} />
            <Route path="/driverList/:did" element={<DriverDetailsScreen />} />
            <Route path="/orderList" element={<OrderListScreen />} />
            <Route path="/manage-order-status" element={<ManageOrderStatus />} />
            <Route path="/assign-property-store" element={<AssignPropertyStore />} />
            <Route path="/assign-additional-delivery" element={<AssignAdditionalDelivery />} />
            <Route path="/orderList/:oid" element={<OrderDetails />} />
            <Route path="/packages" element={<AdminPackages />} />
            <Route path="/support-chat" element={<SupportChat />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/offers/create" element={<OfferCreate />} />
            <Route path="/offer/edit/:oid" element={<OfferEdit />} />
            <Route path="/property-offers" element={<PropertyOffers />} />
            <Route path="/property-offers/create" element={<PropertyOffersCreate />} />
            <Route path="/property-offers/edit/:oid" element={<PropertyOfferEdit />} />
            <Route path="/driver-promotions" element={<DriverPromotions />} />
            <Route path="/driver-promotion/create" element={<DriverPromotionCreate />} />
            <Route path="/driver-promotion/edit/:pid" element={<DriverPromotionEdit />} />
            <Route path="/track-order/:oid" element={<TrackOrder />} />
            <Route path="/orders-feedback" element={<OrderFeedback />} />
            <Route path="/groceries" element={<GroceryListing />} />
          </Route>

          {/* Not Found ROute */}
          <Route path="/support" element={<SupportScreen />} />
          <Route path="*" element={<PageNotFoundScreen />} />
        </Routes>
      </div>
    </>
  );
};

export default App;

import React from 'react';
import LoadingTableComponent from './utilityComponents/LoadingTableComponent';
import { NavLink } from 'react-router-dom';
import DeleteOffer from './DeleteOffer';
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat';

const GroceryListingTable = ({ data, loading, deleteRow }) => {

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                <tr>
                    <th className="px-4 py-3">
                        Title
                    </th>
                    <th className="px-4 py-3">
                        Price
                    </th>
                    <th className="px-4 py-3">
                        Brand
                    </th>
                    <th className="px-4 py-3">
                        Link
                    </th>
                    <th className="px-4 py-3">
                        Category
                    </th>
                    <th className="px-4 py-3">
                        Image
                    </th>
                    <th className="px-4 py-3">
                        ASIN
                    </th>
                </tr>
            </thead>
            {loading ? (
                <LoadingTableComponent cols={7} />
            ) : (
                data?.length > 0 && data.map((entry, index) => (
                    <tbody key={entry._id}>
                        <tr className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4">
                            <td className="px-4 w-fit max-w-[170px]">
                                {entry.Title}
                            </td>
                            <td className="px-4 w-fit max-w-[100px]">
                                {entry.Price}
                            </td>
                            <td className="px-4 w-fit max-w-[140px]">
                                {entry.Brand}
                            </td>
                            <td className="px-4 w-fit max-w-[300px]">
                                <a href={entry.Link} target="_blank" rel="noopener noreferrer">View Product</a>
                            </td>
                            <td className="px-4 w-fit max-w-[120px]">
                                {entry.Category}
                            </td>
                            <td className="px-4 w-fit max-w-[150px]">
                                <img src={entry['Image Link']} style={{height: '100px', width: '100px'}}/>
                            </td>
                            <td className="px-4 w-fit max-w-[100px]">
                                {entry.ASIN}
                            </td>
                        </tr>
                    </tbody>
                ))
            )}
        </table>
    );
}

export default GroceryListingTable;

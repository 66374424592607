import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AdminHeader from '../components/AdminHeader';
import OrdersListComponent from '../components/orderComponents/OrdersListComponent';
import { useSelector } from 'react-redux';
import request from '../utils/request';

export default function ManageOrderStatus() {
  const [limit, setLimit] = useState(1);
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState();
  const { driverList, error } = useSelector((state) => state.allDrivers);
  const location = useLocation();

  let driverData = driverList && driverList.filter((driver) => {
    if (driver.isVerified && driver.checkrVerified === "clear" && driver.isOnBoarded) return driver;
  });

  driverData = driverData && driverData.map((driver, index) => {
    return {
      id: driver._id,
      name: `${driver.firstName} ${driver.lastName}`,
      email: driver.email,
      mobile: driver.phoneNumber,
      checkrVerified: driver.checkrVerified,
      stripeVerified: driver.isOnBoarded ? true : false,
      emailVerified: driver.isVerified === true ? true : false
    };
  });

  useEffect(() => {
    getAllOrders();
  }, [location]); // Trigger when location changes

  const getAllOrders = async () => {
    setLoading(true);
    const response = await request.get(`/orders?status=pending,picked&limit=${limit}`);
    setOrders(response.data);
    setLoading(false);
  };

  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        <div className="px-10 bg-white">
          <AdminHeader heading={"Manage Order Status"} pages={"Pages / Order Status"} />
        </div>

        <div className="px-6 py-5 h-screen overflow-auto">
          <OrdersListComponent driverData={driverData} data={orders} loading={loading} limit={limit} setLimit={setLimit} isManageOrderStatus={true} />
        </div>
      </div>
    </div>
  );
}

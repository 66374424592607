import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import request from '../utils/request';
import EntriesComponent from '../components/EntriesComponent';
import GroceryListingTable from '../components/GroceryListingTable';

const GroceryListing = () => {
    const [data, setData] = useState();
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchOffers()
    }, [limit]);

    const fetchOffers = async () => {
        setLoading(true)
        try {
            const response = await request.get(`admin/get-grocery-listing?limit=${limit}`);
            setData(response.data);
        } catch (e) {
            setData([]);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Grocery List"}
                        pages={"Pages / Grocery List"}
                    />
                </div>
                <div className="px-6 py-5 h-full overflow-auto">
                    <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[80%]">
                        <div className="w-[95%] m-auto flex flex-col">
                            <div className="relative overflow-x-auto">
                                <EntriesComponent search={search} setSearch={setSearch} setLimit={setLimit} />
                                <GroceryListingTable data={data} loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default GroceryListing
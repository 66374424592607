import React, { useState } from "react";

import EntriesComponent from "../EntriesComponent";
import OrderTable from "./OrderTable";

function OrdersListComponent({ data, loading, limit, setLimit, driverData = null, isManageOrderStatus=false }) {

  const [search, setSearch] = useState('');

  if (search.length !== 0) {
    data = (data?.filter(entry => `${entry?.property?.address.line1} ${entry?.property?.address.line2 ? entry?.property?.address.line2 : ''} ${entry?.property?.address.city} ${entry?.property?.address.state}, United States`.toLowerCase().includes(search.toLowerCase()) || entry.inStockDate.substr(0, 10).includes(search.toLowerCase()) || entry.propertyID.toLowerCase().includes(search.toLowerCase()) || entry.guestEmail.toLowerCase().includes(search.toLowerCase()) || entry.uniqueId.toLowerCase().includes(search.toLowerCase())))
  }

  return (
    <div className="bg-[white] p-5">

      <EntriesComponent search={search} setSearch={setSearch} setLimit={setLimit}/>

      <OrderTable driverData={driverData} data={data} limit={limit} loading={loading} isManageOrderStatus={isManageOrderStatus}/>
      {/* {data && <OrderTable data={data} limit={limit} loading={loading} />} */}

    </div>
  )
}

export default OrdersListComponent
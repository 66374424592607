import { createSlice } from "@reduxjs/toolkit";
import { login, signup, getAllOrders_completed, getAllOrders, getRecentOrders, getAllOrdersCount, getAllOrders_pending, getAllOrders_requested,getAllOrders_paymentCompleted, getAllOrders_picked, getOrderDetails } from "../thunks/adminActions";
import { sliderClasses } from "@mui/material";

const loginSlice = createSlice({
    name: "login",
    initialState: {
        loading: false,
        adminInfo: null,
        error: "",
        token: "",
    },
    reducers: {
        removeError: (state) => {
            state.error = "";
        },
        logout: (state) => {
            state.adminInfo = null;
            localStorage.removeItem("adminInfo");
            localStorage.clear();
        },
        loginReset: (state) => {
            state.loading = false;
            state.success = false;
            state.message = "";
            state.error = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.adminInfo = action.payload.user;
                state.token = action.payload.token;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const signupSlice = createSlice({
    name: "signup",
    initialState: {
        loading: false,
        success: false,
        message: "",
        error: "",
    },
    reducers: {
        signupReset: (state) => {
            state.loading = false;
            state.success = false;
            state.message = "";
            state.error = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signup.pending, (state) => {
                state.loading = true;
            })
            .addCase(signup.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.message = action.payload.message;
            })
            .addCase(signup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const allOrdersSlice = createSlice({
    name: "allOrders",
    initialState: {
        loading: false,
        orderCount: {},
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrdersCount.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllOrdersCount.fulfilled, (state, action) => {
                state.loading = false;
                state.orderCount = action.payload;
            })
            .addCase(getAllOrdersCount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const recentOrdersSlice = createSlice({
    name: "recentOrders",
    initialState: {
        loading: false,
        orders: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRecentOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRecentOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload;
            })
            .addCase(getRecentOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const allOrdersCompletedSlice = createSlice({
    name: "allOrdersCompleted",
    initialState: {
        loading: false,
        success: false,
        orders: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders_completed.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllOrders_completed.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.orders = action.payload;

            })
            .addCase(getAllOrders_completed.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const allOrdersPendingSlice = createSlice({
    name: "allOrdersPending",
    initialState: {
        loading: false,
        success: false,
        orders: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders_pending.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllOrders_pending.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.orders = action.payload;

            })
            .addCase(getAllOrders_pending.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const allOrdersRequestedSlice = createSlice({
    name: "allOrdersRequested",
    initialState: {
        loading: false,
        success: false,
        orders: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders_requested.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllOrders_requested.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.orders = action.payload;

            })
            .addCase(getAllOrders_requested.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});


const allOrdersPaymentCompletedSlice = createSlice({
    name: "allOrdersPaymentCompleted",
    initialState: {
        loading: false,
        success: false,
        orders: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders_paymentCompleted.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllOrders_paymentCompleted.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.orders = action.payload;

            })
            .addCase(getAllOrders_paymentCompleted.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const allOrdersPickedSlice = createSlice({
    name: "allOrdersPicked",
    initialState: {
        loading: false,
        success: false,
        orders: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders_picked.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllOrders_picked.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.orders = action.payload;

            })
            .addCase(getAllOrders_picked.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

// orderDetails slice
const orderDetailsSlice = createSlice({
    name: "orderDetails",
    initialState: {
        loading: false,
        order: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getOrderDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrderDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload;
            })
            .addCase(getOrderDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});



// --------------- Alert --------------------------------

const alertSlice = createSlice({
    name: "alert",
    initialState: {
        message: "",
    },
    reducers: {
        hideAlert: (state) => {
            state.message = "";
        },
        showAlert: (state, action) => {
            state.message = action.payload;
        }
    }
});
// --------------- Blur and SideNav Slice ---------------

const sideNavSlice = createSlice({
    name: "sideNav",
    initialState: false,
    reducers: {
        setNavOn: (state) => (state = true),
        setNavOff: (state) => (state = false),
    },
});

// -------------------- Actions -----------------------
export const { hideAlert } = alertSlice.actions
export const { logout, removeError, loginReset } = loginSlice.actions;
export const { signupReset } = signupSlice.actions;
export const { setNavOn, setNavOff } = sideNavSlice.actions;

// ----------------- Slice Exports --------------------------
export { loginSlice, signupSlice, allOrdersSlice, recentOrdersSlice, allOrdersCompletedSlice, allOrdersPendingSlice, allOrdersPickedSlice, allOrdersPaymentCompletedSlice, allOrdersRequestedSlice, orderDetailsSlice, sideNavSlice };

export { alertSlice }

import React, {useEffect, useState} from 'react';
import AdminHeader from '../components/AdminHeader';
import request from '../utils/request';
import LoadingHomeComponent from "../components/packageComponents/LoadingHomeComponent";
import SelectDropdown from "../components/SelectDropdown";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function AssignPropertyStore() {
    const [hosts, setHosts] = useState();
    const [activeHost, setActiveHost] = useState();
    const [loading, setLoading] = useState();
    const [properties, setProperties] = useState([]);
    const [selectedPropertiesData, setSelectedPropertiesData] = useState([]);
    const [activeProperties, setActiveProperties] = useState([]);
    const stores = [
        {
            label: 'Ralphs',
            value: 'Ralphs'
        },
        {
            label: 'WholeFoods',
            value: 'WholeFoods'
        },
        {
            label: 'Sprouts',
            value: 'Sprouts'
        },
        {
            label: 'Vons',
            value: 'Vons'
        }
    ];
    const [selectedValues, setSelectedValues] = useState([]);

    const handleCheckboxChange = (value) => {
        setSelectedValues((prevValues) => {
            if (prevValues.includes(value)) {
                return prevValues.filter((item) => item !== value);
            } else {
                return [...prevValues, value];
            }
        });
    };

    useEffect(() => {
        const getHosts = async () => {
            const response = await request.get('/hosts');
            setHosts(response.data);
        };
        getHosts();
    }, []);

    useEffect(() => {
        if (activeHost && activeHost.value) {
            getProperties();
        }
    }, [activeHost]);

    const hostsData = hosts && hosts.map((host, index) => {
        return {
            label: host.firstName + ' ' + host.lastName,
            value: host._id
        }
    });

    const getProperties = async () => {
        setLoading(true);
        const response = await request.get(`/properties/host/${activeHost.value}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setProperties(response.data);
        setLoading(false);
    };

    const propertiesData = properties && properties.map((property, index) => {
        return {
            label: property.address?.line1 + ' ' + property.address?.city,
            value: property._id
        }
    });

    const assignStore = async () => {
        const propertyIds = activeProperties.map(property => property.value);
        const data = {
            propertyIds,
            stores: selectedValues
        }

        setLoading(true);
        await request.post('/properties/assign-store', data, {
            withCredentials: true, // Include credentials in the request
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setActiveProperties([])
        setActiveHost({})
        setSelectedPropertiesData([])
        setLoading(false)
    }

    const onChangeProperties = (options) => {
        const activePropertiesData = options && options.map((property, index) => {
            const propertyData = properties.find((prop) => prop._id === property.value);
            return {
                property: propertyData,
                stores: propertyData?.stores && Object.keys(propertyData?.stores)?.map(store => {
                    return {
                        value: store,
                        label: store.charAt(0).toUpperCase() + store.slice(1)
                    }
                })
            }
        });

        setSelectedPropertiesData(activePropertiesData);
        setActiveProperties(options);
    }

    const onChangeTableStores = (options, propertyId) => {
        const selectedStoresData = selectedPropertiesData.map((propertyData) => {
            if(propertyData?.property?._id === propertyId) {
                propertyData.stores = options;
                return propertyData;
            }
            return propertyData;
        });

        setSelectedPropertiesData(selectedStoresData);
    }

    const saveIndividualChanges = async (stores, propertyId) => {
        const storesData = stores.map(store => store.value);
        const data = {
            propertyIds: [propertyId],
            stores: storesData
        }
        setLoading(true);
        await request.post('/properties/assign-store', data, {
            withCredentials: true, // Include credentials in the request
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setLoading(false)
    }

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader heading={"Assign Property Store"} pages={"Pages / Assign Property Store"}/>
                </div>
                <div className="px-6 py-5 h-screen overflow-auto">
                    <div
                        className="relative bg-white overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg p-8">
                        {loading ? <LoadingHomeComponent/> : (
                            <>
                                <div className='py-4'>
                                    <div className='flex pb-2 font-bold'>Select Host</div>
                                    <SelectDropdown
                                        value={activeHost}
                                        options={hostsData}
                                        isMulti={false}
                                        onChange={option => setActiveHost(option)}
                                    />
                                </div>
                                <div className='py-4'>
                                    <div className="flex pb-2 font-bold justify-between items-center">
                                        <p>
                                            Select Properties
                                        </p>
                                        <div className="flex items-center space-x-2">
                                            <label>Select All</label>
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    onChangeProperties(e.target.checked ? propertiesData : [])
                                                }
                                            />
                                        </div>
                                    </div>
                                    <SelectDropdown
                                        value={activeProperties}
                                        options={propertiesData}
                                        isMulti={true}
                                        onChange={option => onChangeProperties(option)}
                                    />
                                </div>
                                <div className='py-4'>
                                    <div className='flex pb-2 font-bold'>Select Store</div>
                                    <div className='flex justify-center items-center'>
                                        <div className='mr-4'>
                                            <label className='mr-4'>Ralphs</label>
                                            <input
                                                name='store'
                                                type='checkbox' onChange={(e) => handleCheckboxChange(e.target.value)}
                                                value='Ralphs'
                                                checked={selectedValues.includes("Ralphs")}
                                            />
                                        </div>
                                        <div className='mr-4'>
                                            <label className='mr-4'>WholeFoods</label>
                                            <input
                                                name='store' type='checkbox'
                                                onChange={(e) => handleCheckboxChange(e.target.value)}
                                                value='WholeFoods'
                                                checked={selectedValues.includes("WholeFoods")}
                                            />
                                        </div>
                                        <div className='mr-4'>
                                            <label className='mr-4'>Sprouts</label>
                                            <input
                                                name='store' type='checkbox'
                                                onChange={(e) => handleCheckboxChange(e.target.value)}
                                                value='Sprouts'
                                                checked={selectedValues.includes("Sprouts")}
                                            />
                                        </div>
                                        <div className='mr-4'>
                                            <label className='mr-4'>Vons</label>
                                            <input
                                                name='store'
                                                type='checkbox'
                                                value='Vons'
                                                onChange={(e) => handleCheckboxChange(e.target.value)}
                                                checked={selectedValues.includes("Vons")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='py-4 justify-end flex'>
                                    <button className='bg-primary text-white px-4 py-2 rounded-md'
                                            onClick={assignStore}>Assign Store
                                    </button>
                                </div>
                            </>
                        )}
                        {
                            activeProperties && activeProperties.length > 0 && (
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="border p-4">Property</th>
                                            <th className="border p-4">Store(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {selectedPropertiesData?.map((propertyData) => {
                                        return (
                                            <tr className=''>
                                                <td className="border p-4">{propertyData?.property?.address?.line1} {propertyData?.property?.address?.city}</td>
                                                <td className="border p-4">
                                                    <div className='flex justify-center items-center'>
                                                        <SelectDropdown
                                                            value={propertyData.stores}
                                                            options={stores}
                                                            isMulti={true}
                                                            onChange={option => onChangeTableStores(option, propertyData?.property._id)}
                                                        />
                                                        <div className='p-2'>
                                                            <button className='text-primary' onClick={() => saveIndividualChanges(propertyData.stores, propertyData?.property._id)}>
                                                                <FontAwesomeIcon icon={faCheck}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

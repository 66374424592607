import React, { useState, useEffect } from "react";
import SideNavLink from "./SideNavLink";
import dashboardIcon from "../icons/dashboard.png";
import driverIcon from "../icons/driverIcon.png";
import orderIcon from "../icons/orderIcon.png";
import AssignStore from "../icons/assign-store.png";
import propertyIcon from "../icons/propertyIcon.png";
import packageIcon from "../icons/packageIcon.png";
import chatIcon from "../icons/chat.png";
import offersIcon from "../icons/offersIcon.png";
import propertyOffersIcon from "../icons/property.png";
import driverPromotionIcon from "../icons/driver-icon.png";
import logoutLogo from "../icons/logoutIcon.svg";
import logo from "../icons/logo.jpeg";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { logout } from "../store/slices/adminSlices";

const SideNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        if (window.confirm("Are you sure you want to log out?")) {
            dispatch(logout());
            navigate("/login");
        }
    };

    return (
        <>
            <div
                id="navLeft"
                className="flex-col items-center w-[300px] min-w-[220px] bg-white shadow-5xl sticky z-1 flex top-0 min-h-[650px]"
            >
                {/* Logo */}
                <div className="flex justify-center items-center brand h-[160px] w-[90%] m-auto mt-2">
                    <NavLink to="/dashboard" className="w-[70%]">
                        <img className="w-full" src={logo} alt="" />
                    </NavLink>
                </div>

                {/* Navigation Links */}
                <div className="nav flex-grow w-full mb-20">
                    <ul className="flex flex-col gap-2 justify-center items-center mt-5">
                        <SideNavLink
                            icon={dashboardIcon}
                            label={"Admin Dashboard"}
                            link={"dashboard"}
                            active="activeNav"
                        />

                        <SideNavLink
                            icon={propertyIcon}
                            label={"Property Hosts"}
                            link={`hostList`}
                        />

                        <SideNavLink
                            icon={driverIcon}
                            label={"Drivers"}
                            link={`driverList`}
                        />
                        <SideNavLink 
                            icon={orderIcon} 
                            label={"Manage Order Status"} 
                            link={"manage-order-status"} 
                        />
                        <SideNavLink 
                            icon={orderIcon} 
                            label={"Orders"} 
                            link={"orderList"} 
                        />

                        <SideNavLink
                            icon={AssignStore}
                            label={"Assign Property Store"}
                            link={"assign-property-store"}
                        />
                        <SideNavLink
                            icon={AssignStore}
                            label={"Assign Additional Delivery"}
                            link={"assign-additional-delivery"}
                        />
                        <SideNavLink 
                            icon={orderIcon} 
                            label={"Groceries"} 
                            link={"groceries"} 
                        />
                        <SideNavLink
                            icon={packageIcon}
                            label={"Packages"}
                            link={"packages"}
                        />
                        <SideNavLink
                            icon={chatIcon}
                            label={"Support Chat"}
                            link={"support-chat"}
                        />
                        <SideNavLink 
                            icon={offersIcon} 
                            label={"Offers"} 
                            link={"offers"} 
                        />
                        <SideNavLink
                            icon={propertyOffersIcon}
                            label={"Property Offers"}
                            link={"property-offers"}
                        />
                        <SideNavLink
                            icon={driverPromotionIcon}
                            label={"Driver Promotions"}
                            link={"driver-promotions"}
                        />
                        <SideNavLink
                            icon={driverPromotionIcon}
                            label={"Orders Feedback"}
                            link={"orders-feedback"}
                        />
                    </ul>
                </div>

                {/* Logout Button */}

                <div
                    className="flex justify-center border-t-2 border-lightborder w-full m-auto bottom-5 absolute"
                    onClick={handleLogout}
                >
                    {/* <SideNavLink icon={logoutLogo} label={"Logout"} /> */}
                    <SideNavLink
                        icon={logoutLogo}
                        label={"Logout"}
                        link={location.pathname.slice(1)}
                    />
                </div>
            </div>
        </>
    );
};

export default SideNav;
